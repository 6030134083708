import projectsApi from "@/stores/api/projects.api";
import generalApi from "@/stores/api/general.api";
import authApi from '@/stores/api/auth.api'
import socialsApi from '@/stores/api/socials.api'

export default {
    ...projectsApi,
    ...generalApi,
    ...authApi,
    ...socialsApi
}