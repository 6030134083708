// Styles
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import {aliases, mdi} from 'vuetify/iconsets/mdi-svg'


export default createVuetify({
    breakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1400,
        },
    },
    rtl: false,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                colors: {
                    primary: '#F24139',
                    secondary: '#403E48',
                    primary100: '#FEECEB',
                    bluecool200: '#D9D8DA',
                    bluecool400: '#8C8B91',
                    bluecool300: '#B3B2B6',
                    bluecool600: '#403E48',
                    fireaxered100: '#FBFAFB',
                    fireaxered300: '#EDEBEF',
                    fireaxered200: '#F6F5F7',
                    lightorange: '#FFDDA6',
                    lightorange1: '#FFEBCC',
                    redcool100: '#f241390d',
                    warning: '#FB3',
                    success: '#00C851',
                    error: '#F44'
                },
                dark: false,
            },
        },
        defaultTheme: 'light',
    }
});