import {useAuthStore} from "@/stores/auth";

const authMiddleware = (to, next) => {
    const authStore = useAuthStore();
    const loggedIn = authStore.loggedIn;
    if (!loggedIn) {
        window.location.href = '/login';
        return next(false);
    }
    return next();
};
export default authMiddleware;
