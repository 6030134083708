import router from "@/router";

const cookies = require("js-cookie");
import {toast} from "vue3-toastify";

const roleMiddleware = (to, next) => {
    let roles = cookies.get('userRoles');
    if (roles) {
        let parsedRoles = JSON.parse(roles);
        if (to.meta.roles.filter(k => parsedRoles.includes(k)).length < 1) {
            toast.error('Unauthorized')
            router.push('/')
            return next(false)
        }
        return next();
    }
    return next();
};
export default roleMiddleware;
