import client from "@/services/client";
import config from "../../config";

export default {
    async getPlatforms(payload) {
        return await client.get('/platform/0', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async web3SignIn(payload) {
        return await client.post('/auth/web3/login', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async web3SignUp(payload) {
        return client.parseResponse(await client.post('/auth/web3/register', payload, {
            baseURL: config.url.chirpleyApi
        }))
    },
    async authStepSignIn(payload) {
        return await client.post('/auth/step', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async socialsSignIn(payload) {
        return await client.post(`/auth/${payload.type}/login`, payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async signUp(payload) {
        return client.parseResponse(await client.post(`/auth/request`, payload, {
            baseURL: config.url.chirpleyApi
        }))
    },
    async authenticate(payload) {
        return client.parseResponse(await client.post('/login', payload))
    },
    async resetPassword(payload) {
        return await client.post('/auth/forgot', payload, {
            baseURL: config.url.chirpleyApi
        })
    },
    async signOut(payload) {
        return client.parseResponse(await client.post('/logout', payload))
    },
    async getProfile(payload) {
        return client.parseResponse(await client.get('/me', payload))
    },
    async updateProfile(payload) {
        return client.parseResponse(await client.upload('/me', payload.data, payload.files))
    },
    async getUsersList(payload, customConfig) {
        return client.parseResponse(await client.get('/users/me/balance', payload, {
            ...customConfig,
            baseURL: config.url.chirpleyApi
        }))
    }
}