/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';
import config from '../config/index'

const cookies = require("js-cookie");

class Client {
    constructor() {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE';
        const token = cookies.get('chirpadToken') || '';
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        this.http = axios.create({
            baseURL: `${config.url.api}`,
            headers: {
                "Content-Type": "application/json",
            },
        });

        this.http.interceptors.response.use(response => response, async (error) => {
            if (error.response && error.response.status === 401) {
                cookies.remove('chirpadToken');
                window.location.href = '/login';
            }
            return Promise.reject(error);
        });
    }

    setHeader(key, value) {
        this.http.defaults.common.headers[key] = value;
        return this;
    }

    get(path, params, config) {
        return this.http.get(path, {params, ...config});
    }

    post(path, data, config) {
        return this.http.post(path, data, config);
    }

    upload(path, data, files = [], config) {
        const form = new FormData();

        _.each(files,(file) =>{
            form.append(file.key,file.file)
        })

        const appendToForm = (formData, value, key) => {
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    if (typeof item === 'object' && item !== null) {
                        appendToForm(formData, item, `${key}[${index}]`);
                    } else {
                        formData.append(`${key}[${index}]`, item);
                    }
                });
            } else if (typeof value === 'object' && value !== null) {
                Object.keys(value).forEach((subKey) => {
                    appendToForm(formData, value[subKey], `${key}[${subKey}]`);
                });
            } else {
                formData.append(key, value);
            }
        };

        _.each(data, (value, key) => {
            if (value !== null) {
                appendToForm(form, value, key);
            }
        });

        return this.post(path, form, {
            ...config,
            headers: {'Content-Type': 'multipart/form-data'},
        });
    }

    put(path, data, config) {
        return this.http.put(path, data, config);
    }

    delete(path, params, config) {
        return this.http.delete(path, {params, ...config});
    }

    parseResponse({data}) {
        return data;
    }
}

export default new Client();
