import {useAuthStore} from "@/stores/auth";

const authRedirect = (to, next) => {
    const authStore = useAuthStore();
    const loggedIn = authStore.loggedIn;
    const userRoles = authStore.user.roles;
    if (loggedIn) {
        if (userRoles.includes('admin') || userRoles.includes('brand')) {
            window.location.href = '/admin/dashboard';
        } else if (userRoles.includes('investor')) {
            window.location.href = '/investor/dashboard';
        } else {
            window.location.href = '/';
        }
        return next(false);
    }
    return next();
};
export default authRedirect;
