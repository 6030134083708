<template>
	<router-view />
</template>

<!--<script>-->
<!--const cookies = require("js-cookie");-->

<!--import "./util/Stage";-->
<!--import api from "./services/api";-->
<!--import config from "./config/index";-->
<!--import { useMainStore } from "./stores/store";-->

<!--export default {-->
<!--	components: {},-->
<!--	data: () => ({-->
<!--		ready: false,-->
<!--	}),-->
<!--	methods: {-->
<!--		async loggedIn(reference) {-->
<!--			await this.$OneSignal.User.PushSubscription.optIn();-->
<!--			if (this.$OneSignal.User.PushSubscription.optedIn == true) {-->
<!--				if (reference != undefined) this.$OneSignal.login(reference);-->
<!--			}-->
<!--		},-->
<!--		loadIntercom(user) {-->
<!--			window.intercomSettings = {-->
<!--				api_base: "https://api-iam.intercom.io",-->
<!--				app_id: "t2epcvl4",-->
<!--				name: user.firstname,-->
<!--				user_id: user.reference,-->
<!--				email: user.email,-->
<!--				created_at: user.created_at,-->
<!--				horizontal_padding: 20,-->
<!--				vertical_padding: 65-->
<!--			};-->

<!--			(function () {-->
<!--				var w = window;-->
<!--				var ic = w.Intercom;-->
<!--				if (typeof ic === "function") {-->
<!--					ic("reattach_activator");-->
<!--					ic("update", w.intercomSettings);-->
<!--				} else {-->
<!--					var d = document;-->
<!--					var i = function () {-->
<!--						i.c(arguments);-->
<!--					};-->
<!--					i.q = [];-->
<!--					i.c = function (args) {-->
<!--						i.q.push(args);-->
<!--					};-->
<!--					w.Intercom = i;-->
<!--					var l = function () {-->
<!--						var s = d.createElement("script");-->
<!--						s.type = "text/javascript";-->
<!--						s.async = true;-->
<!--						s.src = "https://widget.intercom.io/widget/t2epcvl4";-->
<!--						var x = d.getElementsByTagName("script")[0];-->
<!--						x.parentNode.insertBefore(s, x);-->
<!--					};-->
<!--					if (document.readyState === "complete") {-->
<!--						l();-->
<!--					} else if (w.attachEvent) {-->
<!--						w.attachEvent("onload", l);-->
<!--					} else {-->
<!--						w.addEventListener("load", l, false);-->
<!--					}-->
<!--				}-->
<!--			})();-->
<!--		},-->
<!--	},-->
<!--	beforeMount() {-->
<!--		const referralParam = this.$route.query.referral;-->
<!--		if (referralParam != undefined) {-->
<!--			cookies.set("chrp_referral", referralParam, {-->
<!--				domain: config.cookiedomain,-->
<!--			});-->
<!--		}-->
<!--		if (-->
<!--			!location.pathname.startsWith("/login") &&-->
<!--			!location.pathname.startsWith("/signup") &&-->
<!--			!location.pathname.startsWith("/register") &&-->
<!--			!location.pathname.startsWith("/password-reset")-->
<!--		) {-->
<!--			if (cookies.get("chrp_refresh_token")) {-->
<!--				api.get(`/v1/users/me/info`)-->
<!--					.then((res) => {-->
<!--						useMainStore().$patch({-->
<!--							user: res.data,-->
<!--						});-->

<!--						//Checking for incomplete account-->
<!--						if (res.data.status == "incomplete") {-->
<!--							this.$router.push("/account-setup");-->
<!--						}-->

<!--						if (res.data.staff == false) {-->
<!--							//Checking for maintenance mode-->
<!--							api.get(`/v1/settings/maintenance_mode`)-->
<!--								.then((res_maintenance) => {-->
<!--									if (res_maintenance.data.data.value == "true") {-->
<!--										this.$router.push("/under-maintenance");-->
<!--									}-->
<!--								})-->
<!--								.catch((err) => {-->
<!--									console.log(err);-->
<!--								});-->
<!--						}-->

<!--						//Get sidebar notification-->
<!--						api.get(`v1/campaign/me/latest`)-->
<!--							.then((res) => {-->
<!--								if (res.data.success) {-->
<!--									useMainStore().$patch({-->
<!--										sidebar: {-->
<!--											notification_closed: false,-->
<!--											notification_url: `/campaigns/${res.data.data.reference}`,-->
<!--										},-->
<!--									});-->
<!--								} else {-->
<!--									useMainStore().$patch({-->
<!--										sidebar: {-->
<!--											notification_closed: true,-->
<!--											notification_url: "",-->
<!--										},-->
<!--									});-->
<!--								}-->
<!--							})-->
<!--							.catch((err) => {-->
<!--								console.log(err);-->
<!--							});-->

<!--						this.loadIntercom(res.data);-->
<!--						this.loggedIn(res.data.reference);-->
<!--						this.ready = true;-->
<!--					})-->
<!--					.catch((err) => {-->
<!--						if (err.response && err.response.status === 401) {-->
<!--							cookies.remove("chrp_access_token", {-->
<!--								domain: config.cookiedomain,-->
<!--								path: "/",-->
<!--							});-->
<!--							cookies.remove("chrp_refresh_token", {-->
<!--								domain: config.cookiedomain,-->
<!--								path: "/",-->
<!--							});-->
<!--							cookies.remove("chrp_user", {-->
<!--								domain: config.cookiedomain,-->
<!--								path: "/",-->
<!--							});-->
<!--							location.replace(`${config.url.login}/login?r=${encodeURI(location.href)}`);-->
<!--						} else {-->
<!--							location.replace(`${config.url.login}/login?r=${encodeURI(location.href)}`);-->
<!--						}-->
<!--					});-->
<!--			} else {-->
<!--				location.replace(`${config.url.login}/login?r=${encodeURI(location.href)}`);-->
<!--			}-->
<!--		} else {-->
<!--			this.ready = true;-->
<!--		}-->
<!--	},-->
<!--};-->
<!--</script>-->
