//Function for capitalizing first letter
Object.defineProperty(String.prototype, "capitalize", {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "shortedAddress", {
    value: function () {
        if (this.length < 6) {
            return this;
        }
        const firstTwo = this.slice(0, 2);
        const lastFour = this.slice(-4);
        return `${firstTwo}...${lastFour}`;
    },
    enumerable: false,
});

Object.defineProperty(Array.prototype, "getPreview", {
    value: function () {
        if (this.length > 0) {
            const firstItem = this[0];
            if (firstItem instanceof Blob || firstItem instanceof File) {
                const blobUrl = URL.createObjectURL(firstItem);
                return blobUrl;
            }
        }
        return null; // Return null if the array is empty or the first item is not a Blob or File
    },
    enumerable: false,
});

Object.defineProperty(Object.prototype, "getOwnPropertyDescriptors", {
    value: function () {
        var obj = JSON.parse(JSON.stringify(this));
        Object.keys(obj).reduce(function (obj, key) {
            if (obj["_" + key] === obj[key]) {
                delete obj["_" + key];
            }
            return obj;
        }, obj);
        return obj;

    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "transformServiceSlug", {
    value: function () {
        const transformedString = this.replace(/([a-z]+)-(\w+)-(\w+)/, "$2 - $3");
        return transformedString;
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatFollowers", {
    value: function () {
        if (!isNaN(this)) {
            let subtitleValue = parseInt(this);

            if (subtitleValue >= 0 && subtitleValue <= 100) {
                return "0 - 99 Followers";
            } else if (subtitleValue >= 100 && subtitleValue <= 1000) {
                return "100 - 999 Followers";
            } else if (subtitleValue >= 1000 && subtitleValue <= 5000) {
                return "1000 - 4999 Followers";
            } else if (subtitleValue >= 5000 && subtitleValue <= 10000) {
                return "5000 - 9999 Followers";
            } else if (subtitleValue >= 10000 && subtitleValue <= 50000) {
                return "10000 - 49999 Followers";
            } else if (subtitleValue >= 50000 && subtitleValue <= 100000) {
                return "50000 - 99999 Followers";
            } else if (subtitleValue >= 100000 && subtitleValue <= 250000) {
                return "100000 - 249999 Followers";
            } else if (subtitleValue >= 250000 && subtitleValue <= 500000) {
                return "250000 - 499999 Followers";
            } else if (subtitleValue >= 500000 && subtitleValue <= 1000000) {
                return "500000 - 999999 Followers";
            } else if (subtitleValue >= 1000000 && subtitleValue <= 2000000) {
                return "1000000 - 1999999 Followers";
            } else {
                return "2000000+ Followers";
            }
        } else {
            return parseInt(this);
        }
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatPlatform", {
    value: function () {
        switch (this.toLowerCase()) {
            case "tkt":
                return "TikTok";
            case "twt":
                return "Twitter/X";
            case "ig":
                return "Instagram";
            case "fb":
                return "Facebook";
            case "onsite":
                return "On location";
        }
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatPlatformSlug", {
    value: function () {
        let val = this.toLowerCase();
        val = val.replace('tkt', 'tiktok');
        val = val.replace('twt', 'twitter');
        val = val.replace('ig', 'instagram');
        val = val.replace('fb', 'facebook');
        val = val.replace('onsite', 'onsite');
        return val;
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatFullPlatformSlug", {
    value: function () {
        let val = this.toLowerCase();
        val = val.replace('tiktok', 'tkt');
        val = val.replace('twitter', 'twt');
        val = val.replace('instagram', 'ig');
        val = val.replace('facebook', 'fb');
        val = val.replace('onsite', 'onsite');
        return val;
    },
    enumerable: false,
});

